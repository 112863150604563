'use client';

import { faMoneyBillWave, faUserXmark } from '@fortawesome/pro-light-svg-icons';
import { faBell } from '@fortawesome/pro-regular-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { usePathname } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';

import { NotificationLookup } from '../utils/types';

type NotificationsPopupProps = {
	notifications: NotificationLookup | undefined;
	checkoutLink: string;
};

const NotificationsPopup = ({ notifications, checkoutLink }: NotificationsPopupProps) => {
	const newNotifications = {
		isBalanceDue: notifications?.isBalanceDue || notifications?.isBalanceDuePbb,
		isWaiverDue: notifications?.isWaiverDue || notifications?.isWaiverDuePbb
	};
	const pathname = usePathname();
	const currentPath = pathname && pathname.split('/');
	const applyWhiteBg =
		(currentPath && currentPath[2] && currentPath[1] === 'players') || (currentPath && currentPath[2] && currentPath[1] === 'results');

	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const popupRef = useRef<HTMLDivElement>(null);

	const notificationClasses = clsx({
		'cursor-pointer bg-gray-300 text-gray-700': isPopupOpen && applyWhiteBg,
		'cursor-pointer bg-white text-gray-700': isPopupOpen && !applyWhiteBg,
		'text-gray-700': !isPopupOpen && applyWhiteBg,
		'text-white': !isPopupOpen && !applyWhiteBg
	});

	const togglePopup = () => {
		setIsPopupOpen((prev) => !prev);
	};

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
				setIsPopupOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const trueCount = Object.values(newNotifications || {}).filter((value) => value === true).length;

	return (
		<div className="hidden sm:relative sm:flex" ref={popupRef}>
			{isPopupOpen ? (
				<>
					<div
						className={`flex h-9 w-[52px] items-center justify-center rounded-lg ${notificationClasses}`}
						onClick={togglePopup}
						data-testid="notifications-button"
					>
						<FontAwesomeIcon icon={faBell} className="cursor-pointer" />
					</div>
					<div className="absolute right-0 top-full z-[70] mt-2 w-[375px] rounded-lg bg-white shadow-lg">
						<div className="flex flex-col gap-4 px-[16px]">
							<div className="flex h-[52px] items-end justify-between">
								<div className="flex w-full flex-row items-center justify-between">
									<h2 className="text-lg font-semibold text-gray-900">Notifications</h2>
									{trueCount > 0 && (
										<div className="mr-2 flex size-4 items-center justify-center rounded-full bg-error-600">
											<p className="flex items-center justify-center text-[10px]">{trueCount}</p>
										</div>
									)}
								</div>
							</div>

							<hr />

							{newNotifications?.isBalanceDue || newNotifications?.isWaiverDue ? (
								<>
									{newNotifications?.isBalanceDue && (
										<a href={`${checkoutLink}`} data-testid="notifications-link-balance-due">
											<div className="flex h-[48px] w-full flex-row items-center gap-4 hover:bg-gray-100">
												<div className="flex h-[48px] w-full flex-row items-center gap-4">
													<div className="flex size-12 items-center justify-center rounded-full border">
														<div className="flex size-12 items-center justify-center">
															<FontAwesomeIcon icon={faMoneyBillWave} className="text-brand-600" />
														</div>
													</div>

													<div className="flex w-full flex-row items-center justify-between">
														<p className="text-sm font-medium text-gray-700">You have balance(s) due</p>
														<FontAwesomeIcon icon={faCircle} className="mr-2 size-2 text-error-600" />
													</div>
												</div>
											</div>
										</a>
									)}

									{newNotifications?.isWaiverDue && (
										<a href={`${checkoutLink}`} data-testid="notifications-link-open-waivers">
											<div className="flex h-[48px] w-full flex-row items-center gap-4 hover:bg-gray-100">
												<div className="flex h-[48px] w-full flex-row items-center gap-4">
													<div className="flex size-12 items-center justify-center rounded-full border">
														<div className="flex size-12 items-center justify-center">
															<FontAwesomeIcon icon={faUserXmark} className="text-brand-600" />
														</div>
													</div>

													<div className="flex w-full flex-row items-center justify-between">
														<p className="text-sm font-medium text-gray-700">You have open waiver(s)</p>
														<FontAwesomeIcon icon={faCircle} className="mr-2 size-2 text-error-600" />
													</div>
												</div>
											</div>
										</a>
									)}
								</>
							) : (
								<div className="flex h-[48px] w-full flex-row items-center gap-4">
									<div className="flex h-[48px] w-full flex-row items-center gap-4">
										<div className="flex w-full flex-row items-center justify-start">
											<p className="text-sm font-medium text-gray-700">No notifications</p>
										</div>
									</div>
								</div>
							)}
							<hr />
						</div>
					</div>
				</>
			) : (
				<div className={`flex h-9 w-[52px] items-center justify-center rounded-lg ${notificationClasses}`} data-testid="notifications-button">
					<div className="relative cursor-pointer" onClick={togglePopup}>
						<FontAwesomeIcon icon={faBell} className="cursor-pointer" />
						{trueCount > 0 && (
							<div className="absolute -top-1 flex size-4 items-center justify-center rounded-full bg-error-600">
								<p className="text-[10px] text-white">{trueCount}</p>
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default NotificationsPopup;

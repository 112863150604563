import { faEllipsis } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, useCollapseNav } from '@pickleballinc/react-ui';
import clsx from 'clsx';
import { usePathname } from 'next/navigation';
import React, { useRef } from 'react';

import { BLOG_LABEL } from '../utils/constants';
import { Application, NavItem } from '../utils/nav.config';
import CustomLink from '../../ui/custom-link';

function isActiveMenuItem(
	href: string,
	pathname: string,
	baseOrigin: string,
	pickleballHref: string,
	clubsHref: string,
	teamLeaguesHref: string,
	leaguesHref: string,
	tournamentHref: string,
	application: Application,
	suffix?: string
): boolean {
	if (baseOrigin) {
		const fullURL = `${baseOrigin}${pathname}`;

		if (baseOrigin.includes(pickleballHref)) {
			const rewrittenHref = href === `https://${pickleballHref}/news/all` ? `https://${pickleballHref}/news` : href;

			return fullURL.startsWith(rewrittenHref);
		} else if ([clubsHref, teamLeaguesHref, leaguesHref, tournamentHref].includes(baseOrigin)) {
			return fullURL.startsWith(href);
		}
	}

	if (application === 'clubs') {
		if (suffix === '/clubs') {
			return true;
		} else {
			return false;
		}
	}

	if (application === 'tournament') {
		if (suffix === '/tournaments') {
			return true;
		} else {
			return false;
		}
	}

	if (application === 'team-leagues') {
		if (suffix === '/team-leagues') {
			return true;
		} else {
			return false;
		}
	}

	return suffix ? pathname.startsWith(suffix) : false;
}

interface MenuItemProps {
	children: React.ReactNode;
	href: string;
	baseOrigin: string;
	pickleballHref: string;
	clubsHref: string;
	teamLeaguesHref: string;
	leaguesHref: string;
	tournamentsHref: string;
	application: Application;
	suffix?: string;
}

const MenuItem = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement> & MenuItemProps>(function MenuItem(props, propsRef) {
	const {
		className,
		href,
		children,
		baseOrigin,
		pickleballHref,
		leaguesHref,
		teamLeaguesHref,
		clubsHref,
		tournamentsHref,
		suffix,
		application,
		...rest
	} = props;
	const pathname = usePathname();

	const menuItemClasses = clsx(
		'relative flex font-semibold text-white transition-all duration-150 hover:text-opacity-60',
		{
			'before:content-[""] before:absolute before:inset-x-0 before:bottom-1 before:h-1 before:bg-white': isActiveMenuItem(
				href,
				pathname || '',
				baseOrigin,
				pickleballHref,
				clubsHref,
				teamLeaguesHref,
				leaguesHref,
				tournamentsHref,
				application,
				suffix
			)
		},
		className
	);

	return (
		<div className={menuItemClasses} {...rest} ref={propsRef}>
			<CustomLink className="flex items-center px-2" href={href} target={props.target}>
				{children}
			</CustomLink>
		</div>
	);
});

interface NavProps {
	baseOrigin: string;
	application: Application;
	pickleballHref: string;
	tournamentsHref: string;
	clubsHref: string;
	teamLeaguesHref: string;
	leaguesHref: string;
	navItems: NavItem[];
}

export const Nav = ({ baseOrigin, application, pickleballHref, tournamentsHref, clubsHref, teamLeaguesHref, leaguesHref, navItems }: NavProps) => {
	const pathname = usePathname();
	const containerRef = useRef<HTMLDivElement>(null);
	const { hidden, registerItem, ready } = useCollapseNav({
		containerRef: containerRef as React.RefObject<HTMLElement>
	});

	return (
		<div
			className={`relative hidden flex-1 gap-1 overflow-hidden text-sm text-white transition-opacity duration-200 sm:inline-flex ${
				ready ? 'opacity-100' : 'opacity-0'
			}`}
			ref={containerRef}
		>
			<ul className="flex items-center gap-1">
				{navItems
					.filter((item) => item.children !== BLOG_LABEL)
					.map((item, index) => (
						<li key={index} className="h-[56px]">
							<MenuItem
								className={`${hidden.some((id) => id === item.id) ? 'select-none opacity-0' : ''} h-full`}
								data-collid={item.id}
								target={item.target}
								rel="noopener noreferrer"
								ref={(el) => registerItem(el, item.id, index)}
								href={item.href}
								suffix={item.suffix}
								baseOrigin={baseOrigin}
								pickleballHref={pickleballHref}
								clubsHref={clubsHref}
								teamLeaguesHref={teamLeaguesHref}
								leaguesHref={leaguesHref}
								tournamentsHref={tournamentsHref}
								application={application}
								data-testid={`nav-${item.children}`}
							>
								{item.children}
							</MenuItem>
						</li>
					))}
			</ul>
			{hidden.length > 0 && (
				<div className="sticky inset-y-0 right-0 ml-auto inline-flex px-6">
					<Menu showArrow={false} placement="bottom" offset={0}>
						<Menu.Trigger asChild>
							<div className="flex cursor-pointer items-center">
								<FontAwesomeIcon icon={faEllipsis} className="block text-xl" />
							</div>
						</Menu.Trigger>
						<Menu.List className="min-w-[160px] !rounded-t-none">
							{navItems
								.filter((item) => hidden.some((id) => id === item.id))
								.map((item) => {
									if (item.children === BLOG_LABEL) return null;

									return (
										<React.Fragment key={item.id}>
											<Menu.Item className="cursor-pointer px-2 py-1 text-sm font-normal hover:bg-none hover:underline">
												<CustomLink
													href={item.href}
													target={item.target}
													className={`outline-0 ${pathname?.startsWith(item.href) ? 'underline' : ''}`}
												>
													{item.children}
												</CustomLink>
											</Menu.Item>
										</React.Fragment>
									);
								})}
						</Menu.List>
					</Menu>
				</div>
			)}
		</div>
	);
};

'use client';

import { faExternalLink, faUserCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';

import NotificationsPopup from './Notification/NotificationsPopup';
import { UserProfile } from './UserProfile';
import { NotificationLookup, User } from './utils/types';
import CustomLink from '../ui/custom-link';

export interface TopNavigationProps {
	user: User | undefined;
	notifications: NotificationLookup | undefined;
	canAccessManage: boolean;
	isTourneyPage?: boolean;
	isTeamLeaguesPage?: boolean;
	isClubsPage?: boolean;
	pickleballHref: string;
	checkoutLink: string;
	ssoLink: string;
	onSSOClick?: () => void;
}

export default function TopNavigation({
	user,
	notifications,
	canAccessManage,
	isTourneyPage,
	isTeamLeaguesPage,
	isClubsPage,
	pickleballHref,
	checkoutLink,
	ssoLink,
	onSSOClick
}: TopNavigationProps) {
	const pathname = usePathname();
	const [baseURL, setBaseURL] = useState('');

	useEffect(() => {
		setBaseURL(window.location.href);
	}, []);

	const currentPath = pathname && pathname.split('/');
	const isWhiteBackground =
		(currentPath && currentPath[2] && currentPath[1] === 'players') || (currentPath && currentPath[2] && currentPath[1] === 'results');

	const topNavigationClasses = clsx('hidden text-white sm:block', {
		'!bg-[#EF612D]': isTeamLeaguesPage,
		'bg-experiment': !isTourneyPage && !isWhiteBackground && !isTeamLeaguesPage,
		'bg-blue-600': isTourneyPage,
		'bg-gray-100': isWhiteBackground
	});

	const pickleballCentralClasses = clsx('inline-flex items-center gap-2 pr-4 text-sm font-medium underline-offset-4 hover:underline', {
		'text-gray-700 decoration-gray-700': isWhiteBackground,
		'decoration-white': !isWhiteBackground
	});

	const loginButtonClasses = clsx(
		'mr-2 flex items-center gap-2 !bg-transparent !py-1.5 text-sm font-medium !leading-none text-gray-700 hover:!bg-transparent',
		{
			'text-white hover:!text-white/80': !isTourneyPage && !isWhiteBackground,
			'text-white hover:!bg-transparent hover:!text-white/80': isTourneyPage,
			'text-experiment hover:!text-experiment/80': isWhiteBackground,
			'cursor-pointer': onSSOClick
		}
	);

	const signUpClasses = clsx('rounded !py-1.5 px-2 text-sm font-medium !leading-none', {
		'!bg-white !text-[#EF6820] hover:!text-[#EF6820]': isTeamLeaguesPage,
		'!bg-white !text-experiment hover:!text-experiment': !isTourneyPage && !isWhiteBackground && !isTeamLeaguesPage,
		'!bg-white !text-blue-600 hover:!text-blue-600': isTourneyPage,
		'!bg-experiment hover:!text-white/80': isWhiteBackground
	});

	const isLocalHost = baseURL?.includes('localhost');

	return (
		<div className={topNavigationClasses} id="pb-topnav">
			<ul className="mx-auto flex h-10 max-w-[1200px] items-center justify-between px-4">
				<li className="divide-x divide-gray-400">
					<CustomLink
						href="https://pickleballcentral.com"
						target="_blank"
						className={pickleballCentralClasses}
						data-testid={`pre-header-link-pickleball-central`}
					>
						<span className="inline-flex">Shop Pickleball Central</span>
						<FontAwesomeIcon icon={faExternalLink} size="sm" />
					</CustomLink>

					{(isTourneyPage || isClubsPage || isTeamLeaguesPage) && (
						<CustomLink
							href={`http${isLocalHost ? '' : 's'}://${pickleballHref}`}
							className={`${pickleballCentralClasses} hidden px-4 md:inline`}
							data-testid={`pre-header-link-pickleball-com`}
						>
							<span className="inline-flex">Pickleball.com</span>
						</CustomLink>
					)}
				</li>
				<li className="divide-x divide-gray-400">
					<CustomLink
						href={`http${isLocalHost ? '' : 's'}://${pickleballHref}/watch-now`}
						className={pickleballCentralClasses}
						data-testid={`pre-header-link-watch-now`}
					>
						<span className="inline-flex">Where to Watch Pickleball</span>
					</CustomLink>
				</li>
				<li className="flex items-center gap-2" data-testid={`pre-header-user-profile`}>
					{user?.uuid ? (
						<>
							<NotificationsPopup notifications={notifications} checkoutLink={`${checkoutLink}/pending`} />
							<UserProfile
								pickleballHref={pickleballHref}
								ssoLink={ssoLink}
								user={user}
								isWhiteBackground={isWhiteBackground ? isWhiteBackground : false}
								canAccessManage={canAccessManage}
								isLocalhost={isLocalHost}
							/>
						</>
					) : (
						<>
							{onSSOClick && !isLocalHost ? (
								<div className={loginButtonClasses} rel="nofollow" data-testid={`pre-header-user-profile-login`} onClick={onSSOClick}>
									<FontAwesomeIcon icon={faUserCircle} size="sm" className="text-base" />
									Log In
								</div>
							) : isLocalHost ? (
								<CustomLink
									href={`/login?continue=${encodeURIComponent(baseURL)}`}
									className={loginButtonClasses}
									rel="nofollow"
									data-testid={`pre-header-user-profile-login`}
								>
									<FontAwesomeIcon icon={faUserCircle} size="sm" className="text-base" />
									Log In
								</CustomLink>
							) : (
								<a
									href={`${ssoLink}/?flow=SelfService&continue=${encodeURIComponent(baseURL)}`}
									className={loginButtonClasses}
									rel="nofollow"
									data-testid={`pre-header-user-profile-login`}
								>
									<FontAwesomeIcon icon={faUserCircle} size="sm" className="text-base" />
									Log In
								</a>
							)}
							<a
								href={`${ssoLink}/v3/signup?continue=${encodeURIComponent(baseURL)}`}
								className={signUpClasses}
								rel="nofollow"
								data-testid={`pre-header-user-profile-register`}
							>
								Sign Up
							</a>
						</>
					)}
				</li>
			</ul>
		</div>
	);
}
